exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .play-button_play-button_cXELI {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    overflow: hidden;  /* Mask the icon animation */\n    width: 2.5rem;\n    height: 2.5rem;\n    background-color: hsla(300, 53%, 60%, 1);\n    color: hsla(0, 100%, 100%, 1);\n    border-radius: 50%;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n} .play-button_play-button_cXELI {\n    position: absolute;\n    top: .5rem;\n    z-index: auto;\n} .play-button_play-button_cXELI:focus {\n    outline: none;\n} .play-button_play-icon_33XpJ {\n  width: 50%;\n} [dir=\"ltr\"] .play-button_play-button_cXELI {\n    right: .5rem;\n} [dir=\"rtl\"] .play-button_play-button_cXELI {\n    left: .5rem;\n}\n", ""]);

// exports
exports.locals = {
	"play-button": "play-button_play-button_cXELI",
	"playButton": "play-button_play-button_cXELI",
	"play-icon": "play-button_play-icon_33XpJ",
	"playIcon": "play-button_play-icon_33XpJ"
};