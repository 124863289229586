exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .security-manager-modal_modal-content_33sCQ {\n    width: 440px;\n    margin-top: 25px;\n} .security-manager-modal_body_1jZgz {\n    background: hsla(0, 100%, 100%, 1);\n    padding: 1rem 1.5rem;\n} [theme=\"dark\"] .security-manager-modal_body_1jZgz {\n    color: var(--text-primary, hsla(225, 15%, 40%, 1));\n    background: var(--ui-primary, hsla(215, 100%, 95%, 1));\n} .security-manager-modal_body_1jZgz p {\n    margin: 8px 0;\n} .security-manager-modal_buttons_3qEck {\n    display: flex;\n} .security-manager-modal_buttons_3qEck :disabled {\n    opacity: 0.8;\n} .security-manager-modal_deny-button_dMRqK, .security-manager-modal_allow-button_3liNF {\n    flex-grow: 1;\n    font: inherit;\n    color: inherit;\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    border: 1px solid var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n    font-weight: 600;\n    font-size: 0.85rem;\n} .security-manager-modal_deny-button_dMRqK {\n    background-color: rgb(255, 92, 92);\n    color: white;\n    margin-right: 8px;\n} .security-manager-modal_allow-button_3liNF {\n    background-color: #24cd11;\n    color: black;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "security-manager-modal_modal-content_33sCQ",
	"modalContent": "security-manager-modal_modal-content_33sCQ",
	"body": "security-manager-modal_body_1jZgz",
	"buttons": "security-manager-modal_buttons_3qEck",
	"deny-button": "security-manager-modal_deny-button_dMRqK",
	"denyButton": "security-manager-modal_deny-button_dMRqK",
	"allow-button": "security-manager-modal_allow-button_3liNF",
	"allowButton": "security-manager-modal_allow-button_3liNF"
};