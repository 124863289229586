/* eslint-disable no-unused-vars */
import { initExtension } from '../reducers/cc-extension';
import {
    addLocales,
    updateLocale
} from '../reducers/locales';

// Extension settings are temporarily unavailable for Umi
/*
import {addNewSetting} from '../reducers/settings';
import {newExtensionSettings} from '../reducers/extension-settings';
*/
import JSZip from 'jszip';
import mime from 'mime-types';
import vm from 'vm';

import ClipCCExtension from 'clipcc-extension';
import VM from 'scratch-vm';

import rxFSIconURL from './libraries/extensions/rxfs/rxfs.svg';
import rxFSInsetIconURL from './libraries/extensions/rxfs/rxfsicon.svg';


import iframeIconURL from './libraries/extensions/iframe/iframemain.png';

import messageIconURL from './libraries/extensions/message/messagemain.jpg';

import ShellIconURL from './libraries/extensions/shell/shellmain.svg';

import r3dIconURL from './libraries/extensions/r3d/r3d.jpg';

import anitextIconURL from './libraries/extensions/anitext/anitextmain.png';
import anitextInsetIconURL from './libraries/extensions/anitext/anitext.svg';

import EvalIconURL from './libraries/extensions/eval/evalmain.png';

import ListIconURL from './libraries/extensions/list/list.svg';
import lazyaIconURL from './libraries/extensions/lazyload/lazyload.svg';
import rtimejsIconURL from './libraries/extensions/rtimejs/runtimemain.png';
import toolIconURL from './libraries/extensions/tool/tool.svg';
import OperationIconURL from './libraries/extensions/Operation/operations.png';

//import localIconURL from './libraries/extensions/local/local.png';

import musicIconURL from './libraries/extensions/music/music.png';
import musicInsetIconURL from './libraries/extensions/music/music-small.svg';

import penIconURL from './libraries/extensions/pen/pen.png';
import penInsetIconURL from './libraries/extensions/pen/pen-small.svg';

import videoSensingIconURL from './libraries/extensions/videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './libraries/extensions/videoSensing/video-sensing-small.svg';

import text2speechIconURL from './libraries/extensions/text2speech/text2speech.png';
import text2speechInsetIconURL from './libraries/extensions/text2speech/text2speech-small.svg';

import translateIconURL from './libraries/extensions/translate/translate.png';
import translateInsetIconURL from './libraries/extensions/translate/translate-small.png';

import makeymakeyIconURL from './libraries/extensions/makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './libraries/extensions/makeymakey/makeymakey-small.svg';

import microbitIconURL from './libraries/extensions/microbit/microbit.png';
import microbitInsetIconURL from './libraries/extensions/microbit/microbit-small.svg';
import microbitConnectionIconURL from './libraries/extensions/microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './libraries/extensions/microbit/microbit-small.svg';

import ev3IconURL from './libraries/extensions/ev3/ev3.png';
import ev3InsetIconURL from './libraries/extensions/ev3/ev3-small.svg';
import ev3ConnectionIconURL from './libraries/extensions/ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './libraries/extensions/ev3/ev3-small.svg';

import wedo2IconURL from './libraries/extensions/wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './libraries/extensions/wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './libraries/extensions/wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './libraries/extensions/wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './libraries/extensions/wedo2/wedo-button-illustration.svg';

import boostIconURL from './libraries/extensions/boost/boost.png';
import boostInsetIconURL from './libraries/extensions/boost/boost-small.svg';
import boostConnectionIconURL from './libraries/extensions/boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './libraries/extensions/boost/boost-small.svg';
import boostConnectionTipIconURL from './libraries/extensions/boost/boost-button-illustration.svg';

import gdxforIconURL from './libraries/extensions/gdxfor/gdxfor.png';
import gdxforInsetIconURL from './libraries/extensions/gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './libraries/extensions/gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './libraries/extensions/gdxfor/gdxfor-small.svg';

import twIcon from './libraries/extensions/tw/tw.svg';

import customExtensionIcon from './libraries/extensions/custom/custom.svg';
import pythonIconURL from './libraries/extensions/python/pythonmain.png';

import terminalIconURL from './libraries/extensions/terminal/terminalmain.svg';
import galleryIcon from './libraries/extensions/gallery/gallery.svg';


const builtinExtensions = [
    {
        name: 'Eval',
        extensionId: 'eval',
        author: '大主教大祭司',
        iconURL: EvalIconURL,
        description: "rc.rxblocks.eval.discribe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'Shell',
        extensionId: 'shell',
        author: '大主教大祭司',
        iconURL: ShellIconURL,
        description: "rc.rxblocks.shell.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: '流式传输',
        extensionId: 'liquid',
        author: '大主教大祭司',
        iconURL: customExtensionIcon,
        description: "流式媒体传输",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'Python',
        extensionId: 'python',
        author: '大主教大祭司',
        iconURL: pythonIconURL,
        description: "rc.rxblocks.python.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: '3D (不要使用)',
        extensionId: 'r3d',
        author: '大主教大祭司',
        iconURL: r3dIconURL,
        description: "真正的3D引擎！",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'Terminal',
        extensionId: 'terminal',
        author: '大主教大祭司',
        iconURL: terminalIconURL,
        description: "rc.rxblocks.terminal.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'rc.rxblocks.window.name-u',
        extensionId: 'window',
        author: '大主教大祭司',
        iconURL: customExtensionIcon,
        description: "rc.rxblocks.window.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: "gui.extension.music.name",
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: "gui.extension.music.description",
        tags: ['scratch'],
        featured: true
    },
    {
        name: "gui.extension.pen.name",
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: "gui.extension.pen.description",
        tags: ['scratch'],
        featured: true
    },
    {
        name: "rc.rxblocks.anitext.name",
        extensionId: 'text',
        author: 'ScratchLab',
        iconURL: anitextIconURL,
        insetIconURL: anitextInsetIconURL,
        description: "rc.rxblocks.anitext.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "gui.extension.videosensing.name",
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: "gui.extension.videosensing.description",
        tags: ['scratch'],
        featured: true
    },
    {
        name: 'rc.rxblocks.list.name-u',
        extensionId: 'list',
        author: '大主教大祭司',
        iconURL: ListIconURL,
        description: "rc.rxblocks.list.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'rc.rxblocks.next.name-u',
        extensionId: 'next',
        author: '大主教大祭司',
        iconURL: customExtensionIcon,
        description: "rc.rxblocks.next.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: 'rxFS',
        extensionId: 'rxfs',
        author: '大主教大祭司',
        iconURL: rxFSIconURL,
        insetIconURL: rxFSInsetIconURL,
        description: "rc.rxblocks.rxfs.describe",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: "rc.rxblocks.iframe.name-u",
        extensionId: 'iframe',
        author: 'PenguinMod',
        iconURL: iframeIconURL,
        description: "rc.rxblocks.iframe.describe",
        tags: ['rcodenow'],
        featured: true,
        requirement: ['internet']
    },
    {
        name: "rc.rxblocks.message.name-u",
        extensionId: 'message',
        author: '大主教大祭司',
        iconURL: messageIconURL,
        description: "rc.rxblocks.message.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "rc.rxblocks.runtime.name-u",
        extensionId: 'rtimejs',
        author: '大主教大祭司',
        iconURL: rtimejsIconURL,
        description: "rc.rxblocks.runtime.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "rc.rxblocks.lazya.name-u",
        extensionId: 'lazyAudio',
        author: '大主教大祭司',
        iconURL: lazyaIconURL,
        description: "rc.rxblocks.lazya.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "rc.rxblocks.tool.name-u",
        extensionId: 'tool',
        iconURL: toolIconURL,
        description: "rc.rxblocks.tool.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "be.Operation.Operation-u",
        extensionId: 'Operation',
        iconURL: OperationIconURL,
        description: "be.Operation.describe",
        tags: ['rcodenow'],
        featured: true,
    },
    {
        name: "gui.extension.text2speech.name",
        extensionId: 'text2speech',
        author: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: "gui.extension.text2speech.description",
        tags: ['scratch'],
        featured: true,
        requirement: ['internet']
    },
    {
        name: "gui.extension.translate.name",
        extensionId: 'translate',
        author: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: "gui.extension.translate.description",
        tags: ['scratch'],
        featured: true,
        requirement: ['internet']
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        author: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: "gui.extension.makeymakey.description",
        tags: ['scratch'],
        featured: true
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        author: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: "gui.extension.microbit.description",
        tags: ['scratch'],
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        requirement: ['internet'],
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: "gui.extension.microbit.connectingMessage",
        helpLink: 'https://scratch.mit.edu/microbit'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        author: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: "gui.extension.ev3.description",
        tags: ['scratch'],
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        requirement: ['internet'],
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: "gui.extension.ev3.connectingMessage",
        helpLink: 'https://scratch.mit.edu/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        author: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: "gui.extension.boost.description",
        tags: ['scratch'],
        featured: true,
        disabled: false,
        requirement: ['internet', 'bluetooth'],
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: "gui.extension.boost.connectingMessage",
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        author: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: "gui.extension.wedo2.description",
        tags: ['scratch'],
        featured: true,
        disabled: false,
        requirement: ['internet', 'bluetooth'],
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: "gui.extension.wedo2.connectingMessage",
        helpLink: 'https://scratch.mit.edu/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        author: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: "gui.extension.gdxfor.description",
        tags: ['scratch'],
        featured: true,
        disabled: false,
        requirement: ['internet', 'bluetooth'],
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: "gui.extension.gdxfor.connectingMessage",
        helpLink: 'https://scratch.mit.edu/vernier'
    },
    {
        name: "tw.twExtension.name",
        extensionId: 'tw',
        iconURL: twIcon,
        description: "tw.twExtension.description",
        tags: ['rcodenow'],
        featured: true
    },
    {
        name: "tw.customExtension.name",
        extensionId: '',
        iconURL: customExtensionIcon,
        description: "tw.customExtension.description",
        tags: ['rcodenow'],
        featured: true
    },
];


const loadBuiltinExtension = dispatch => {
    for (const ext of builtinExtensions) {
        ClipCCExtension.extensionManager.addInstance(ext.extensionId, {
            id: ext.extensionId,
            icon: ext.iconURL,
            inset_icon: ext.insetIconURL,
            author: ext.author,
            requirement: ext.requirement,
            api: 0,
            version: '1.0.0'
        }, new ClipCCExtension.Extension());
        dispatch(initExtension(ext));
    }
};


const initExtensionAPI = (gui, vm, blocks) => {
    global.ClipCCExtension = ClipCCExtension;
    const apiInstance = {
        gui: gui.extensionAPI,
        vm: vm.extensionAPI,
        blocks: blocks
    };
    ClipCCExtension.api.registExtensionAPI(apiInstance);
};
const loadExtensionFromFile = async (dispatch, file, type) => {
    let info = {};
    let isReload = false;
    switch (type) {
        case 'ccx': {
            const zipData = await JSZip.loadAsync(file);
            let settings = null;
            let instance = null;

            // Load info
            if ('info.json' in zipData.files) {
                const content = await zipData.files['info.json'].async('text');
                info = JSON.parse(content);
                if (ClipCCExtension.extensionManager.exist(info.id)) {
                    log.warn('reloading extension...');
                    try {
                        ClipCCExtension.extensionManager.removeInstance(info.id);
                        ClipCCExtension.extensionManager.unloadExtensions(
                            [info.id],
                            extension => ClipCCExtension.api.getVmInstance().extensionManager.unloadExtensionURL(info.id)
                        );
                        dispatch(disableExtension(info.id));
                        isReload = true;

                        log.info('reload complete');
                    } catch (e) {
                        log.error('error occurred while reloading', e);
                    }
                }
                if (info.icon) {
                    const data = await zipData.files[info.icon].async('arraybuffer');
                    info.icon = URL.createObjectURL(new Blob(
                        [data], { type: mime.lookup(info.icon) || 'image/png' }
                    ));
                }
                if (info.inset_icon) {
                    const data = await zipData.files[info.inset_icon].async('arraybuffer');
                    info.inset_icon = URL.createObjectURL(new Blob(
                        [data], { type: mime.lookup(info.inset_icon) || 'image/svg+xml' }
                    ));
                }
                info.api = 1;
            } else {
                throw new Error('Cannot find \'info.json\' in ccx extension.');
            }


            // Load extension class
            if ('main.js' in zipData.files) {
                const script = new vm.Script(await zipData.files['main.js'].async('text'));
                const ExtensionPrototype = script.runInThisContext();
                instance = new ExtensionPrototype();
            } else {
                throw new Error('Cannot find \'main.js\' in ccx extension');
            }

            // Load settings
            if ('settings.json' in zipData.files) {
                const content = await zipData.files['settings.json'].async('text');
                settings = JSON.parse(content);
            }

            // Load locale
            const locale = {};
            for (const fileName in zipData.files) {
                const result = fileName.match(/^locales\/([A-Za-z0-9_-]+).json$/);
                if (result) {
                    locale[result[1]] = JSON.parse(await zipData.files[fileName].async('text'));
                }
            }
            if (info.default_language && locale.hasOwnProperty(info.default_language)) { // default language param
                locale.default = locale[info.default_language];
            } else {
                locale.default = locale.en;
            }

            const extensionInfo = {
                extensionId: info.id,
                name: `${info.id}.name`,
                description: `${info.id}.description`,
                iconURL: info.icon,
                insetIconURL: info.inset_icon,
                author: info.author,
                requirement: info.requirement,
                instance: instance,
                api: info.api,
                version: info.version,
                fileContent: file
            };

            if (settings) loadSettings(dispatch, info.id, settings);
            ClipCCExtension.extensionManager.addInstance(info.id, info, instance);
            dispatch(addLocales(locale));
            dispatch(updateLocale());
            dispatch(initExtension(extensionInfo));
            if (isReload) dispatch(enableExtension(info.id));
            break;
        }
        case 'js': {
            const Extension = vm.runInThisContext(file);
            const instance = new Extension();
            info = instance.getInfo();
            const apiInstance = new ClipCCExtension.CompatibleExtension(instance);
            const extensionInfo = {
                extensionId: info.id,
                iconURL: info.blockIconURL,
                insetIconURL: info.blockIconURL,
                author: 'External Extension',
                name: info.name,
                description: 'External Extension',
                requirement: [],
                instance: apiInstance,
                api: 1,
                fileContent: file
            };

            ClipCCExtension.extensionManager.addInstance(info.id, {
                id: info.id,
                icon: info.blockIconURL,
                inset_icon: info.blockIconURL,
                author: info.author,
                requirement: info.requirement,
                api: 1
            }, instance);
            dispatch(initExtension(extensionInfo));
            break;
        }
        /* case 'scx': {
            const url = URL.createObjectURL(file);
            this.props.vm.extensionManager.loadExtensionURL(url);
            break;
        }*/
        default: {
            throw new Error(`Unkown extension type *.${type}`);
        }
    }
    ClipCCExtension.extensionManager.loadExtensionsWithMode(
        [info],
        extension => VM.extensionManager.loadExtensionURL(extension)
    );
    return info;
};

const loadCcx = async (dispatch, file) => {
    let info = {};
    let isReload = false;

    const zipData = await JSZip.loadAsync(file);
    let instance = null;

    // Load info
    if ('info.json' in zipData.files) {
        const content = await zipData.files['info.json'].async('text');
        info = JSON.parse(content);
        if (ClipCCExtension.extensionManager.exist(info.id)) {
            console.warn('reloading extension...');
            try {
                ClipCCExtension.extensionManager.removeInstance(info.id);
                ClipCCExtension.extensionManager.unloadExtensions(
                    [info.id],
                    extension => ClipCCExtension.api.getVmInstance().extensionManager.unloadExtensionURL(info.id)
                );
                dispatch(disableExtension(info.id));
                isReload = true;
                console.log('reload complete');
            } catch (e) {
                console.error('error occurred while reloading', e);
            }
        }
        if (info.icon) {
            const data = await zipData.files[info.icon].async('arraybuffer');
            info.icon = URL.createObjectURL(new Blob(
                [data], {
                type: mime.lookup(info.icon)
            }
            ));
        }
        if (info.inset_icon) {
            const data = await zipData.files[info.inset_icon].async('blob');
            info.inset_icon = URL.createObjectURL(new Blob(
                [data], {
                type: mime.lookup(info.inset_icon)
            }
            ));
        }
        info.api = 1;
    } else {
        throw new Error('Cannot find \'info.json\' in ccx extension.');
    }


    // Load extension class
    if ('main.js' in zipData.files) {
        const script = new vm.Script(await zipData.files['main.js'].async('text'));
        const ExtensionPrototype = script.runInThisContext();
        instance = new ExtensionPrototype();
    } else {
        throw new Error('Cannot find \'main.js\' in ccx extension');
    }

    // Load locale
    const locale = {};
    for (const fileName in zipData.files) {
        const result = fileName.match(/^locales\/([A-Za-z0-9_-]+).json$/);
        if (result) {
            locale[result[1]] = JSON.parse(await zipData.files[fileName].async('text'));
        }
    }
    if (info.default_language && locale.hasOwnProperty(info.default_language)) {
        // default language param
        locale.default = locale[info.default_language];
    } else {
        locale.default = locale.en;
    }

    const extensionInfo = {
        extensionId: info.id,
        name: `${info.id}.name`,
        description: `${info.id}.description`,
        iconURL: info.icon,
        insetIconURL: info.inset_icon,
        author: info.author,
        requirement: info.requirement,
        instance: instance,
        api: info.api,
        version: info.version,
        fileContent: file,
        incompatibleWithScratch: true,
        tags: ['clipcc']
    };

    ClipCCExtension.extensionManager.addInstance(info.id, info, instance);
    dispatch(addLocales(locale));
    dispatch(updateLocale());
    dispatch(initExtension(extensionInfo));
    if (isReload) dispatch(enableExtension(info.id));
    if (global.extensionzip) {
        JSZip.loadAsync(global.extensionzip)
            .then(function () {
                global.extensionzip.file(extensionInfo.name, file);
            });
            
        console.log(global.extensionzip)
    }else{
        global.extensionzip = new JSZip();
        JSZip.loadAsync(global.extensionzip)
        .then(function () {
            global.extensionzip.file(extensionInfo.name, file);
        });
        console.log(global.extensionzip)
    }
    return info;
};

export {
    loadBuiltinExtension,
    initExtensionAPI,
    loadCcx,
    loadExtensionFromFile
};