exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .menu_menu_1rWB9 {\n    position: absolute;\n    border: 1px solid var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n    border-radius: 0 0 8px 8px;\n    background-color: #2e982e;\n    padding: 0;\n    margin: 0;\n    min-width: 186px;\n    /* tw: no max width */\n    /* max-width: 260px; */\n    overflow: visible;\n    color: hsla(0, 100%, 100%, 1);\n    box-shadow: 0 8px 8px 0 hsla(0, 0%, 0%, 0.15);\n} [theme=\"dark\"] .menu_menu_1rWB9 {\n    background-color: #22262e;\n} .menu_menu_1rWB9.menu_left_1Zl7K {\n    right: 0;\n} .menu_menu_1rWB9.menu_right_12vPx {\n    left: 0;\n} .menu_menu-item_3ELPx {\n    display: block;\n    line-height: 34px;\n    white-space: nowrap;\n    padding: 0 10px;\n    font-size: .75rem;\n    margin: 0;\n    font-weight: bold;\n} .menu_menu-item_3ELPx.menu_active_7GxsI,\n.menu_menu-item_3ELPx:hover {\n    background-color: var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n} .menu_menu-item_3ELPx.menu_hoverable_3mGWm {\n    cursor: pointer;\n} .menu_menu-section_1ai_E {\n    border-top: 1px solid var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n}\n", ""]);

// exports
exports.locals = {
	"menu": "menu_menu_1rWB9",
	"left": "menu_left_1Zl7K",
	"right": "menu_right_12vPx",
	"menu-item": "menu_menu-item_3ELPx",
	"menuItem": "menu_menu-item_3ELPx",
	"active": "menu_active_7GxsI",
	"hoverable": "menu_hoverable_3mGWm",
	"menu-section": "menu_menu-section_1ai_E",
	"menuSection": "menu_menu-section_1ai_E"
};