exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .green-flag_green-flag_mk1Vo {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    user-drag: none;\n    cursor: pointer;\n} .green-flag_green-flag_mk1Vo:hover {\n    background-color: hsla(0, 100%, 65%, 0.15);\n} .green-flag_green-flag_mk1Vo.green-flag_is-active_17xP9 {\n    background-color: #2e982e59;\n}\n", ""]);

// exports
exports.locals = {
	"green-flag": "green-flag_green-flag_mk1Vo",
	"greenFlag": "green-flag_green-flag_mk1Vo",
	"is-active": "green-flag_is-active_17xP9",
	"isActive": "green-flag_is-active_17xP9"
};